<template>
  <div class="apply">
    <div class="card">
      <div class="container">
        <img :src="info.images" />
        <div class="info">
          <h1>
            {{ info.bookname }}
            <br />
            <span>有效期：{{ info.validity == 0 ? "永久" : info.validity+'天' }}</span>
          </h1>
          <p class="gold-num">￥{{ info.price }}</p>
        </div>
      </div>
    </div>

    <div class="main">
      <div class="box">
        <p>开课老师：{{ siteInfo.teacher_name }}</p>
        <p>开课地点：{{ siteInfo.address }}</p>
        <p>开课时间：{{ siteInfo.starttime }}</p>
        <p>剩余席位：{{ siteInfo.num }}</p>
      </div>

      <div class="box" @click="personAdd">
        <div class="row">
          <b>上课人</b>
          <i class="jym-tianjia1"></i>
        </div>
        <p v-for="(item, index) in select_Person" :key="index">
          {{ item.name }}
          {{ item.tel }}
          <i class="jym-icon-test59 fr"></i>
        </p>
      </div>

      <div class="box">
        <div class="row">
          <span>课程金额</span>
          <em>￥{{ info.price }}</em>
        </div>
        <div class="row">
          <span>座位保证金</span>
          <em class="gold-num">￥{{ positionPrice }}</em>
        </div>
        <div class="row">
          <span></span>
          <span>×{{ select_Person.length }}</span>
        </div>

        <div class="row" v-if="discount_price > 0 && is_self">
          <span>优惠金额</span>
          <span>-￥{{ discount_price }}</span>
        </div>
        <div class="row">
          <b>应付金额</b>
          <em
            class="gold-num"
          >￥{{ parseFloat(info.price*select_Person.length) + parseFloat(positionPrice*select_Person.length) - parseFloat(is_self ? discount_price : 0) }}</em>
        </div>
      </div>

      <div class="btn-bottom">
        <button class="btn-black" @click="pay">立即支付</button>
      </div>
    </div>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";
import { lessonConfirm, createOrder } from "@api/public";
export default {
  name: "apply",
  data() {
    return {
      id: "",
      kf_id: "",
      sid: "",
      site_id:0,
      info: {},
      siteInfo: {},
      cert_ids: [],
      select_Person: [],
      discount_price: "",
      positionPrice: "",
      is_self: true,
    };
  },
  watch: {
    select_Person(val) {
      val.forEach((item) => {
        this.cert_ids.push(Number(item.cert_id));
      });
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id || "";
      this.sid = this.$route.query.sid || "";
      this.kf_id = this.$route.query.kf_id || 0;
      this.select_Person = this.$route.query.select_Person
        ? JSON.parse(this.$route.query.select_Person)
        : [];
      if (this.$route.query.select_Person) {
        this.is_self = Boolean(this.$route.query.is_self) || false;
      }
    }
    if (sessionStorage.getItem('site_id')){
        this.site_id = sessionStorage.getItem('site_id');
    }
    this.getList();
  },
  methods: {
    // 获取列表数据
    getList() {
      let that = this;
      let params = {
        id: that.id,
        sid: that.sid,
      };

      lessonConfirm(params)
        .then((res) => {
          let data = res.data;
          that.$set(that, "info", data.info);
          that.$set(that, "siteInfo", data.siteInfo);
          that.$set(that, "positionPrice", data.positionPrice);
          that.$set(that, "discount_price", data.discount_price);
          if (that.select_Person.length == 0) {
            that.select_Person.unshift({
              cert_id: data.certInfo.cert_id,
              name: data.certInfo.name,
              tel: data.certInfo.tel,
            });
          }
        })
        .catch((err) => {
          let status = err.data.status;

          that.$set(that, "info", err.data.data.info);
          that.$set(that, "siteInfo", err.data.data.siteInfo);
          that.$set(that, "positionPrice", err.data.data.positionPrice);
          that.$set(that, "discount_price", err.data.data.discount_price);
          that.$dialog.error(err.msg);
          if (status === 101) {
            // 课程不存在
            that.$router.push({ path: "/" });
          } else if (status === 201 || status === 202) {
            // 免费课程不需要购买 || 您已购买该课程
            that.$router.push({ path: "/lesson/offline/" + that.id });
          } else if (status === 301 || status === 403) {
            // 您还有该课程未付款订单
            that.$router.push({ path: "/user/MyLesson" });
          } else if (status === 401) {
            // 完善您的个人信息
            MessageBox({
              title: "提示",
              message: err.msg,
              showCancelButton: true,
            }).then((status) => {
              if (status === "confirm") {
                that.$router.push({
                  path: "/lesson/completeInfo",
                  query: {
                    id: that.id,
                    sid: that.sid,
                    cert_id: err.data.data.certInfo.cert_id,
                    isdefault: err.data.data.certInfo.isdefault,
                    path: "/lesson/applyDetail",
                  },
                });
              } else {
                that.$router.go(-1);
              }
            });
          } else {
            return true;
          }
        });
    },

    // 添加人员
    personAdd() {
      let that = this;
      that.$router.push({
        path: "/lesson/personList",
        query: { id: that.id, sid: that.sid, cert_ids: that.cert_ids },
      });
    },

    // 支付
    pay() {
      let that = this;
      if (that.cert_ids.length > 0) {
        let params = {
          id: that.id,
          sid: that.sid,
          cert_ids: that.cert_ids,
          kf_id: that.kf_id,
          site_id:that.site_id
        };
        createOrder(params)
          .then((res) => {
            that.$router.push({
              path: `/lesson/pay`,
              query: { order_id: res.data.order_id },
            });
          })
          .catch((err) => {
            that.$dialog.error(err.msg);

            const status = err.data.status;
            if (status == 201 || status == 202) {
              // 跳转到订单详情页
              setTimeout(() => {
                that.$router.push(
                  `/lesson/orderDetail/${err.data.data.order_id}`
                );
              }, 2000);
            }else if (status === 301 || status == 403) {
              setTimeout(() => {
                that.$router.push({ path: "/user/MyLesson" });
              }, 2000);
            } else {
              return
            }
          });
      } else {
        that.$dialog.error("至少添加一位上课人！");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.apply {
  padding-bottom: 1rem;
  .card {
    background: white;
    .container {
      padding: 0.3rem;
      display: flex;
      justify-content: space-between;
      height: 2.6rem;
      img {
        width: 50%;
        height: 100%;
        background: black;
        border-radius: 0.2rem 0 0 0.2rem;
      }
      .info {
        width: 50%;
        padding: 0.1rem 0.2rem;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        box-shadow: 0 0.02rem 0.06rem #dddddd;
        border-radius: 0 0.2rem 0.2rem 0;
        h1 {
          font-size: 0.28rem;
          font-weight: 500;
          span {
            font-weight: 500;
            font-size: 0.24rem;
          }
        }
      }
    }
  }

  .main {
    .box {
      background: white;
      padding: 0.2rem 0.3rem;
      margin-top: 0.2rem;
      font-size: 0.28rem;
      p {
        margin: 0.1rem 0;
      }
      .row {
        display: flex;
        justify-content: space-between;
        padding: 0.1rem 0;
        em {
          font-family: PingFang SC;
        }
      }
    }
    .box:first-child {
      margin: 0;
    }
  }
}
</style>
